import { TableColumnType, Fa } from '@eltoro-ui/components'
import { Targetjobservicev1Audience } from 'next-gen-sdk'
import {
  formatDate,
  getJobCounts,
  getTargetIconET,
  simplifyEnum,
  userFriendlyAudienceName,
} from 'Helpers'
import { EmptyField, InfoPopover, OrderLineCount } from 'Components'
import { ETIcon } from 'Assets'
import { audienceStatusOptions, audienceTypeOptions } from 'searchOptions'
import {
  AudienceStatusIndicator,
  AudienceStatusPopover,
} from './AudienceStatusPopover'

type AudienceTableColumnsType = TableColumnType<Targetjobservicev1Audience>[]

// If this is not a function, the label in the status column will not render and break the app
// (I'm assuming InfoPopover is not built at the time this trying to build?)
// leaving that for a later refactor/investigation
export const AudienceTableColumns = (): AudienceTableColumnsType => [
  {
    path: 'name',
    label: 'Audience Name',
    filterOn: 'string',
    RowCell: (row) => {
      return (
        <div className="AudienceLibrary__icon-filename flex max-w-full flex-1 items-center gap-1">
          <ETIcon
            className="AudienceLibrary__icon-custom h-7 w-auto"
            icon={row.type ? getTargetIconET(row.type) : 'target'}
          />
          {row.locked && (
            <span data-tooltip="top" aria-label="This audience is locked.">
              <Fa icon="lock" size={1} />
            </span>
          )}
          {row.dataProduct === 'DATA_PRODUCT_CUSTOM_AUDIENCE' && (
            <span data-tooltip="top" aria-label="This is a premium audience.">
              <Fa icon="fa-gem" size={1} type="duotone" />
            </span>
          )}
          {row.hidden && (
            <span data-tooltip="top" aria-label="This audience is hidden.">
              <Fa
                className="AudienceLibrary__user-secret m-auto"
                icon="user-secret"
                size={1}
              />
            </span>
          )}
          <span className="AudienceLibrary__filename flex-1 break-all">
            {row.name || 'No name'}
          </span>
        </div>
      )
    },
  },
  {
    path: 'type',
    label: 'Audience Type',
    filterOn: audienceTypeOptions,
    RowCell: (row) => {
      if (!row.type) return <EmptyField />
      return `${userFriendlyAudienceName(row.type)}${
        row.dataProduct === 'DATA_PRODUCT_CUSTOM_AUDIENCE' && row.dataSource
          ? ` (${
              row.dataSource === 'DATA_SOURCE_5X5'
                ? 'Intent-to-Home'
                : simplifyEnum(2, row.dataSource)
            })`
          : ''
      }`
    },
  },
  {
    path: 'count',
    label: 'Count',
    removeFilter: true,
    removeSort: true,
    RowCell: (row) => {
      if (
        !row.id ||
        (row.type === 'AUDIENCE_TYPE_RETARGETING' && getJobCounts(row) === 0) ||
        (row.status !== 'AUDIENCE_STATUS_READY' &&
          row.status !== 'AUDIENCE_STATUS_COMPLETED')
      )
        return <EmptyField />
      if (row.type === 'AUDIENCE_TYPE_IPSFORGEOJSON') {
        const count = getJobCounts(row)
        return count ? 'Matched' : <EmptyField />
      }
      return getJobCounts(row)
    },
  },
  {
    path: 'orderLines',
    label: 'Order Lines',
    removeFilter: true,
    removeSort: true,
    RowCell: (row) => {
      return <OrderLineCount item={row} />
    },
  },
  {
    path: 'status',
    label: (
      <div className="flex items-center">
        Status
        <InfoPopover content={<AudienceStatusPopover />} />
      </div>
    ),
    filterOn: audienceStatusOptions,
    RowCell: (row) => {
      if (!row.status) return <EmptyField />
      return <AudienceStatusIndicator status={row.status} />
    },
  },
  {
    path: 'create_time',
    label: 'Date Created',
    removeFilter: true,
    RowCell: (row) => {
      if (!row.createTime) return <EmptyField />
      return formatDate(row.createTime)
    },
  },
  {
    path: 'id',
    label: 'Audience ID',
    filterOn: 'string',
  },
]
