import {
  showSuccessMessage,
  Table,
  TableColumnType,
} from '@eltoro-ui/components'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  LibraryHeader,
  MainWithSide,
  PreviewPanel,
  LibraryTableWrapper,
  PageContent,
  LibraryTableAttachButton,
  LinkButton,
  EmptyField,
  OrgNotes,
} from 'Components'
import {
  Campaignservicev1Audience,
  Targetjobservicev1Audience,
  V1AudienceType,
  V1DataProduct,
  V1ProductType,
} from 'next-gen-sdk'
import {
  LibraryContextProvider,
  useAppContext,
  useCampaignContext,
  useLibraryContext,
} from 'Contexts'
import { checkIfCreative, getJobCounts } from 'Helpers'
import { AudienceTableColumns } from './AudienceTableColumns'
import './AudienceLibraryPage.scss'

type AudienceLibraryPropsType = {
  selectedAudiences?: Targetjobservicev1Audience[]
  overrideModalPreview?: boolean
  filterByType?: V1AudienceType[]
  filterByProductType?: V1ProductType[]
  filterByDataProductType?: V1DataProduct[]
  onAddAudienceToAttach?: (audience: Targetjobservicev1Audience) => void
  onRemoveAudienceFromAttach?: (audience: Targetjobservicev1Audience) => void
}

export const AudienceLibrary = ({
  selectedAudiences,
  overrideModalPreview,
  filterByType,
  filterByProductType,
  filterByDataProductType,
  onAddAudienceToAttach,
  onRemoveAudienceFromAttach,
}: AudienceLibraryPropsType) => {
  const { pathname } = useLocation()
  const history = useNavigate()
  const { currentOrg, isAdmin } = useAppContext()
  const { launchFreshCampaign } = useCampaignContext()
  const {
    currentData: targets,
    handleClearSelectedItem,
    selectedItem,
    handleRowClick,
    currentFolderPath,
    setCurrentFolderPath,
    handleSort,
    handleTableFilter,
    childOrgsOnly,
  } = useLibraryContext()
  const isStandAlone = pathname.includes('/audiences/audience-library')

  const baseColumns = (): TableColumnType<Targetjobservicev1Audience>[] => {
    let columns = AudienceTableColumns()
    if (!isStandAlone)
      columns = AudienceTableColumns().filter(
        (col) => col.path !== 'orderLines',
      )
    // If there is a strict type filter, remove the basic type filter
    if (
      (filterByType && filterByType.length > 0) ||
      (filterByProductType && filterByProductType.length > 0) ||
      (filterByDataProductType && filterByDataProductType.length > 0)
    ) {
      columns = columns.map((col) => {
        if (col.path === 'type') return { ...col, removeFilter: true }
        return col
      })
    }
    return columns
  }
  const childOrgsForFilter = currentOrg?.childOrgs
    ?.map((co) => {
      return { label: co.name || '', value: co.id || '' }
    })
    .sort((a, b) => a.label.localeCompare(b.label))

  const columns: TableColumnType<Targetjobservicev1Audience>[] = [
    ...baseColumns(),
    // Adding child org column
    ...(childOrgsOnly
      ? [
          {
            path: 'child_orgs',
            label: 'Child Org',
            filterOn: childOrgsForFilter || [],
            removeSort: true,
            RowCell: (row) => {
              const matchingChildOrg = currentOrg?.childOrgs?.find(
                (org) => row.orgId === org.id,
              )
              if (matchingChildOrg?.name)
                return (
                  row.orgId && (
                    <LinkButton
                      text={matchingChildOrg?.name}
                      onClick={(e) => {
                        e.stopPropagation()
                        if (matchingChildOrg.id)
                          history(`/orgs/${matchingChildOrg.id}/org-settings`)
                      }}
                      truncate
                    />
                  )
                )
              return null
            },
          } as TableColumnType<Targetjobservicev1Audience>,
        ]
      : []),
    // Folder name
    ...(currentFolderPath.length === 1
      ? [
          {
            path: 'folder',
            label: 'Folder Name',
            filterOn: 'string',
            RowCell: (row) => {
              if (!row.folder) return <EmptyField />
              return (
                <LinkButton
                  text={row.folder}
                  onClick={(e) => {
                    e.stopPropagation()
                    setCurrentFolderPath(['', row.folder || ''])
                  }}
                  truncate
                />
              )
            },
          } as TableColumnType<Targetjobservicev1Audience>,
        ]
      : []),
    // Adding buttons for create campaign view
    ...(!isStandAlone
      ? [
          {
            path: '',
            label: '',
            alignment: 'right',
            removeFilterIcon: true,
            removeFilter: true,
            removeSort: true,
            RowCell: (row) => {
              const isAttached = selectedAudiences?.some(
                (selectedAudience) =>
                  selectedAudience.id === row.id ||
                  selectedAudience.audienceId === row.id ||
                  (selectedAudience as Campaignservicev1Audience).audienceId ===
                    row.id,
              )
              const count = getJobCounts(row)
              return (
                <LibraryTableAttachButton
                  isAttached={isAttached || false}
                  onClick={(e) => {
                    // prevent row click
                    e?.stopPropagation()
                    if (row?.id) {
                      if (isAttached && onRemoveAudienceFromAttach)
                        onRemoveAudienceFromAttach(row)
                      if (!isAttached && onAddAudienceToAttach)
                        onAddAudienceToAttach(row)
                    }
                  }}
                  disabled={
                    (row.status !== 'AUDIENCE_STATUS_READY' &&
                      row.status !== 'AUDIENCE_STATUS_COMPLETED' &&
                      row.type !== 'AUDIENCE_TYPE_VR') ||
                    (row.type !== 'AUDIENCE_TYPE_RETARGETING' && !count)
                  }
                  disabledReason={(() => {
                    if (!count)
                      return 'This audience has 0 matches and cannot be attached to an order line.'
                    return 'This audience cannot be attached to an order line yet.'
                  })()}
                  allowDetach={!!onRemoveAudienceFromAttach} // user needs to remove from the AudienceSelector in this case
                />
              )
            },
          } as TableColumnType<Targetjobservicev1Audience>,
        ]
      : []),
  ]

  const handleOnUseInCampaign = () => {
    // if pathname contains audience library:
    if (isStandAlone) {
      // clear+add to campaign context
      if (selectedItem && !checkIfCreative(selectedItem))
        launchFreshCampaign({ audience: selectedItem })
      // redirect to create campaign
      const query = currentOrg?.id ? `?org_id=${currentOrg.id}` : ''
      history(`/campaigns/create${query}`)
      showSuccessMessage(
        selectedItem?.name || 'SUCCESS',
        'Your audience will be added to your new campaign after set up',
      )
      return
    }
    // if already in campaign creation:
    if (selectedItem?.id && !checkIfCreative(selectedItem)) {
      // add the audience to the cart
      const isAttached = selectedAudiences?.filter(
        (attached) => attached.id === selectedItem.id,
      ).length
      if (!isAttached && onAddAudienceToAttach)
        // need to add logic to remove from 'to attach' list as well as attached list
        onAddAudienceToAttach(selectedItem)
      handleClearSelectedItem()
    }
  }

  return (
    <PageContent className="AudienceLibraryPage pt-0">
      <MainWithSide
        onSideClose={() => handleClearSelectedItem()}
        showAsModal={overrideModalPreview}
        side={
          selectedItem && (
            <PreviewPanel
              previewItem={selectedItem}
              onUseInCampaign={handleOnUseInCampaign}
              isAttached={
                !!(
                  selectedItem?.id &&
                  selectedAudiences !== undefined &&
                  selectedAudiences?.filter(
                    (attached) => attached.id === selectedItem.id,
                  ).length > 0
                )
              }
            />
          )
        }
      >
        {isStandAlone && (
          <LibraryHeader
            type="audience"
            onUpload={() => {
              const query = currentOrg?.id ? `?org_id=${currentOrg.id}` : ''
              history(`/audiences/create${query}`)
            }}
          />
        )}
        {isAdmin && <OrgNotes />}
        <LibraryTableWrapper type="audiences">
          <Table
            className="AudienceLibraryTable"
            columns={columns}
            rows={targets as Targetjobservicev1Audience[]}
            onClick={handleRowClick}
            clicked={selectedItem as Targetjobservicev1Audience}
            onSort={handleSort}
            onFilter={handleTableFilter}
          />
        </LibraryTableWrapper>
      </MainWithSide>
    </PageContent>
  )
}

export const AudienceLibraryPage = (props: AudienceLibraryPropsType) => (
  <LibraryContextProvider
    type="audiences"
    filterByType={props.filterByType}
    filterByProductType={props.filterByProductType}
    filterByDataProductType={props.filterByDataProductType}
  >
    <AudienceLibrary {...props} />
  </LibraryContextProvider>
)
