import { useState } from 'react'
import { Fa, Text, Tabs } from '@eltoro-ui/components'
import { ETIcon } from 'Assets'
import { FileRequirements, PolygonMap, RetargetingPixel } from 'Components'
import { Targetjobservicev1Audience, V1AudienceType } from 'next-gen-sdk'
import { AudienceLibraryPage } from 'Pages'
import { uploaderContainerHeaderText } from './configs'
import { AudienceUploadStepper } from './components'

const libraryFilters: { id: string; filter: V1AudienceType[] }[] = [
  { id: 'address', filter: ['AUDIENCE_TYPE_B2B', 'AUDIENCE_TYPE_B2C'] },
  { id: 'ip', filter: ['AUDIENCE_TYPE_IP'] },
  {
    id: 'new-mover',
    filter: [
      'AUDIENCE_TYPE_ESCROWMOVER',
      'AUDIENCE_TYPE_POSTMOVER',
      'AUDIENCE_TYPE_PREMOVER',
    ],
  },
  { id: 'dc', filter: ['AUDIENCE_TYPE_DC'] },
  { id: 'zip', filter: ['AUDIENCE_TYPE_ZIP'] },
  {
    id: 'device',
    filter: ['AUDIENCE_TYPE_DEVICE', 'AUDIENCE_TYPE_DEVICES_FOR_HOUSEHOLDS'],
  },
  { id: 'reverseIp', filter: ['AUDIENCE_TYPE_REVERSE'] },
  {
    id: 'polygon',
    filter: ['AUDIENCE_TYPE_IPSFORWKB', 'AUDIENCE_TYPE_IPSFORGEOJSON'],
  },
  { id: 'venue-replay', filter: ['AUDIENCE_TYPE_VR'] },
  { id: 'retargeting', filter: ['AUDIENCE_TYPE_RETARGETING'] },
  { id: 'ipForMailing', filter: ['AUDIENCE_TYPE_ADDRESSES_FOR_IP'] },
]

export const AudienceUploadContainer = ({
  selectedAudiences,
  audienceType,
  onCancel,
  onAddAudienceToAttach,
  onRemoveAudienceFromAttach,
  // attachedCampaignAudiences,
  showLibrary,
}: {
  selectedAudiences: Targetjobservicev1Audience[]
  audienceType: string
  onCancel: () => void
  onAddAudienceToAttach?: (audience: Targetjobservicev1Audience) => void
  onRemoveAudienceFromAttach?: (audience: Targetjobservicev1Audience) => void
  attachedCampaignAudiences?: Targetjobservicev1Audience[]
  showLibrary?: boolean
}) => {
  const currentUploaderText = uploaderContainerHeaderText.find(
    (text) => text.id === audienceType,
  )
  const [selected, setSelected] = useState<string>('')

  const handleSelect = (audience: Targetjobservicev1Audience) => {
    if (onAddAudienceToAttach) onAddAudienceToAttach(audience)
    setSelected('GetFromLibrary')
  }
  const isVR = audienceType === 'venue-replay'
  const UploadContent = (
    <>
      {audienceType !== 'venue-replay' && audienceType !== 'polygon' && (
        <>
          <div className="AudienceUpload__tab-upload relative flex gap-1">
            <Text
              className="AudienceUpload__tab-upload flex items-center gap-1"
              tag="h3"
            >
              {currentUploaderText?.title}
              {currentUploaderText && currentUploaderText.subTitle ? (
                <span className="font-light">
                  {currentUploaderText?.subTitle}
                </span>
              ) : null}
            </Text>
            <FileRequirements audienceType={audienceType} />
          </div>
          <Text tag="p" className="text-ml py-2">
            {currentUploaderText?.description}
          </Text>
          <hr className="border-b-thin border-tint-khaki-300 tablet:hidden laptop:hidden mobile:hidden mt-2 mb-4" />
        </>
      )}
      <div className="AudienceUpload__wrap animate-slidedown">
        {(() => {
          if (audienceType === 'venue-replay' || audienceType === 'polygon')
            return (
              <PolygonMap
                vr={isVR}
                onAddAudienceToAttach={onAddAudienceToAttach}
                onRemoveAudienceFromAttach={onRemoveAudienceFromAttach}
              />
            )
          if (audienceType === 'retargeting')
            return (
              <RetargetingPixel
                onAddAudienceToAttach={onAddAudienceToAttach}
                onRemoveAudienceFromAttach={onRemoveAudienceFromAttach}
              />
            )
          return (
            <AudienceUploadStepper
              audienceType={audienceType}
              onCancel={onCancel}
              onAddAudienceToAttach={onAddAudienceToAttach}
            />
          )
        })()}
      </div>
    </>
  )
  if (!showLibrary) return UploadContent
  return (
    <div className="AudienceUploadContainer">
      <Tabs
        tabs={[
          {
            id: 'upload',
            label: (
              <>
                <ETIcon className="AudienceUpload__tabs--icon" icon="target" />
                <span className="AudienceUpload__tabs--text">
                  Upload new file
                </span>
              </>
            ),
            defaultTab: selected === 'upload',
            component: UploadContent,
          },
          {
            id: 'GetFromLibrary',
            label: (
              <>
                <span
                  aria-label="Select from Audience Library"
                  data-tooltip="left"
                >
                  <Fa
                    className="text-primary"
                    icon="folders"
                    size={3}
                    type="duotone"
                  />
                </span>
                <span className="AudienceUpload__tabs--text">
                  Select from Audience Library
                </span>
              </>
            ),
            defaultTab: selected === 'GetFromLibrary',
            component: (
              <AudienceLibraryPage
                selectedAudiences={selectedAudiences}
                onAddAudienceToAttach={handleSelect}
                onRemoveAudienceFromAttach={onRemoveAudienceFromAttach}
                overrideModalPreview
                filterByType={
                  libraryFilters.find((filter) => filter.id === audienceType)
                    ?.filter
                }
                filterByDataProductType={
                  audienceType === 'custom'
                    ? ['DATA_PRODUCT_CUSTOM_AUDIENCE']
                    : undefined
                }
              />
            ),
          },
        ]}
      />
    </div>
  )
}
